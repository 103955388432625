import * as React from "react";
import { Partners, PartnersCard } from "@brainfinance/icash-component-library";
import { useScreenSize } from "../../helpers";
import { PARTNERS } from "./partners-data";

export const PartnersSection = () => {
  const screenSize = useScreenSize();
  
  return (
    <Partners className="mt-0 mb-[66px] md:mb-[44px] sm:mb-[16px]">
      {screenSize !== "sm" && (
        <PartnersCard name={PARTNERS.ccfa.name} image={PARTNERS.ccfa.image} />
        )}
      <PartnersCard name={PARTNERS.comodo.name} image={PARTNERS.comodo.image} />
      <PartnersCard name={PARTNERS.transUnion.name} image={PARTNERS.transUnion.image} />
      <PartnersCard name={PARTNERS.interac.name} image={PARTNERS.interac.image} />
      <PartnersCard name={PARTNERS.equifax.name} image={PARTNERS.equifax.image} />
      {screenSize !== "sm" && (
        <PartnersCard name={PARTNERS.ownedByWomen.name} image={PARTNERS.ownedByWomen.image} />
      )}
    </Partners>
  );
};

PartnersSection.defaultProps = {
  title: "Trusted partners to keep you safe",
  gray: "you safe",
  description:
    "iCash has partnered with some of the most trusted companies in their respective industries. Keeping our customers personal information safe is our utmost priority."
}