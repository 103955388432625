// Images
import ccfa from "@images/partners/partner-logo-ccfa.png";
import comodo from "@images/partners/partner-logo-comodo.png";
import transunion from "@images/partners/partner-logo-transunion.png";
import interac from "@images/partners/partner-logo-interac.png";
import equifax from "@images/partners/partner-logo-equifax.png";
import ownedByWomen from "@images/partners/partner-logo-owned-by-women.png";

export const PARTNERS = {
  ccfa: {
    name: "CCFA",
    children:
      "As a member of CCFA, iCash actively participates in the regulation of payday lenders to help maintain access to safe credit for Canadians.",
    image: ccfa
  },
  comodo: {
    name: "Comodo",
    children:
      "As the leading cloud-based cyber-security platform, Comodo Secure allows iCash customers to feel a sense of security that their personal information is being protected at the highest level.",
    image: comodo
  },
  transUnion: {
    name: "TransUnion",
    children:
      "iCash understands that consumers are more than their credit score. And so does TransUnion. Working together, we are able to get a clear and accurate overview and therefore loan with confidence.",
    image: transunion
  },
  interac: {
    name: "Interac",
    children:
      "As a leader in online payment solutions, Interac has garnered a solid reputation for security in online money exchange. With Interac, iCash instant loans are able to be sent within 5 minutes, safely and securely.",
    image: interac
  },
  equifax: {
    name: "Equifax",
    children:
      "Equifax is a respected leader in the credit industry. Working together, iCash is able to access trusted insights in order to offer instant loans in a more responsible manner to Canadians.",
    image: equifax
  },
  ownedByWomen: {
    name: "OwnedByWomen",
    image: ownedByWomen
  }
};